import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import arrivals from "../../assets/images/opportunities/01.svg";
import locations from "../../assets/images/opportunities/02.svg";
import zone from "../../assets/images/opportunities/03.svg";
import development from "../../assets/images/opportunities/04.svg";

const opportunities = () =>{
    const settings = {
        // className: "center",
        // infinite: true,
        // centerPadding: "60px",
        slidesToShow: 3,
        centerMode: true,
        // centerPadding: "15%",
        dots: true,
        arrows: false,
        speed: 500,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    variableWidth: false,
                    centerMode: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    variableWidth: false,
                    centerMode: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                    centerMode: false,
                }
            },
        ]
    };

    const data = [
        {
            id: 1,
            icon: arrivals,
            title: "Travel Industry ",
            subtitle: "Travel and Tourism is one of the largest service sector globally that contributes to the economy. Where Tourism services such as stay and attractions are well poised, reflects a wealth of opportunity for locals and businesses. ",
        },
        {
            id: 2,
            icon: locations,
            title: "Post - Pendemic situation",
            subtitle: "Places of stay and services around 'bed and board' will see an increase in demand as countries open up. High quality hotels and resorts will attract tourists and staycation seekers. ",
        },
        {
            id: 3,
            icon: zone,
            title: "NFTs as Memberships",
            subtitle: "NFTs memberships utility has seen unparalleled growth in the last year. As businesses seek new ways of taking care of their most loyal customers, new technologies of access and benefit transference is emerging as the next best way of marketing, creating brand loyalty and fund raising.",
        },
        {
            id: 4,
            icon: development,
            title: "Land Development",
            subtitle: "Owners of land had always had many ideas to develop their land but many do not understand the intricacies of infrastructure, soil, and permissions to start their projects. Many would be frozen with uncertainty while their plots of land appreciate in value but not develop into farms, attractions and or buildings. A team of builders, planners, architects, and the ability to fund the projects would pose significant opportunities..",
        },
    ]
    return(
        <>
            <section id="opportunities" className="opportunities_main common" >
                
                <div id="opportunity-wrap" ></div>
                <h5 className="heading text-center" >OPPORTUNITIES</h5> 
                <Container>
                    <Row className="justify-content-center" >
                        <Col xl={12} lg={12} >
                            <Row>
                            <Slider {...settings}>
                                {data.map((d,i) => (
                                    <Col lg={4} className='opportunities_box'>
                                        <div className="opportunities_content" >
                                            <img src={d.icon} alt="oppo" />
                                            <h5>{d.title}</h5>
                                            <p>{d.subtitle}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Slider>

                            

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default opportunities;