import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
// import vision from "../../assets/images/vision_img.png";
import vision from "../../assets/images/vision_2.png";

const VisionMission = () =>{
    return(
        <>
            <section id="vision" className="vision_main common" >
                <Container >
                    <Row>
                        <Col lg={{ order: 'first' , span: 5 }} md={{ order: 'last' , span: 12 }}  sm={{ order: 'last' , span: 12 }} xs={{ order: 'last' , span: 12 }} >
                            <div className="vision_content" >
                                <h5 className="heading" >Our Vision and Mission</h5> 
                                <div className="vision_content_sub" > 
                                    <p>To become a world class hub for collaboration in development of viable land into tourist spots everyone can enjoy while giving more to the community staking their trust in us.
<br /><br />Everyone can be a part of building and developing land, resorts, attractions and more. Believers and buyers of our NFTs should be rewarded for being first owners of the NFTs and for hodling them.
</p>  
                                </div>
                            </div>
                        </Col>
                        <Col lg={{ order: 'last' , span: 7 }} md={{ order: 'first' , span: 12 }} sm={{ order: 'first' , span: 12 }} xs={{ order: 'first' , span: 12 }} >
                            <div className="vision_image" >
                                <img src={vision} alt="team" width="100%"  height="auto" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default VisionMission;