import React from 'react';
import ReactDOM from 'react-dom';
import { Col, Container, Row , Accordion , Modal } from "react-bootstrap";
import roadmap from "../../assets/images/water_nft.png";
import { useState } from "react";

import pin from "../../assets/images/pin.svg";
import nft1 from "../../assets/images/land/nft1.png";
import nft2 from "../../assets/images/land/nft2.png";
import nft3 from "../../assets/images/land/nft3.png";

const Roadmap = () =>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <>
           <div id="roadmap" className="roadmap_main common" >
                <h5 className="heading text-center" >ROADMAP</h5> 
                <Container>
                    <Row>
                        <Col xl={6} >
                            <div className="roadmap_img" >
                                <img width="400" height="auto" src={roadmap} alt="roadmapimg" />
                            </div>
                        </Col>
                        <Col xl={6} >
                            <div className="roadmap_content" >
                                <div className="phase" >
                                    <h5>Phase 1</h5>
                                    {/* <h6>What is NFT ? </h6> */}
                                    <p>A non-fungible token (NFT) is a non-interchangeable unit of data stored on a blockchain, a form of digital ledger , which can be sold and traded.</p>
                                    <p>Through our digital platform (DigiGuru), shares of the project can be digitized and issued as tokens on the blockchain. A digital share certificate will be stored within each token. Each token will represent one share in our collection. Whitelisted individuals will be able to mint our NFTs before everyone else. Please follow all our socials to find out how to be whitelisted.</p>
                                    <p>Digital contract agreement with number of tokens will be issued in our platform.</p>
                                    <p>The genesis collection of DigiGuru/land holds the aim of developing a piece of land in Batam. The owners are ready and the permits to build are well on their way.</p>
                                    <h6>DigiGuru Holder Benefits:</h6>
                                    <p>
HODL points to exchange for vouchers for travel expenses in Batam (restaurants and dining, sim cards, travel insurance, and more)
Cheaper prices for stay on development and attractions DigiGuru Tycoon Benefits (Owns 5 of our NFTS):</p>
                                    <p>Holding our NFTs will be able to get you:
Points will be equivalent to 2 day 1 night stay on the development 
VIP experiences (launch, parties and events)
First tranche of 10,000 NFTs be sold to raise funds for first phase development of infrastructure to ensure the 2nd phase can happen.</p>
                                    <p>10,000 digital tokens will be released this year and the price of minting will ne announced soon.</p>
                                </div>
                                <div className="phase" >
                                    <h5>Phase 2</h5>
                                    <p>Begin building the infrastructure required to build hotels or apartments.</p>
                                    <p>Another tranche of NFTs will be released for mint at a price that reflects the rest of the capital required to complete the required number of stays (resort apartments/hotel) (phase 2). In Phase 2 we will be giving our Genesis collection holders whitelists and we expect to finish minting most of it even before the public mint seeing the deliverables (free 2-day 1-night stay) are rolling out and members and the public start to see real value in holding our NFTs.</p>
                                </div>
                                <div className="phase" >
                                    <h5>Phase 3</h5>
                                    <p>Complete building of the 2 hectare of land. Some basic infrastructure will be built concurrently. Potential examples include Restaurants, Retail mall, High tech Agricultural park and theme park. In Phase 3, we would like to have enough capacity to build a sea facing resort with a range of amenities and potentially a theme park. At this phase, we would be able to benefit the holders of the genesis collection and second collection immensely and
not only continue giving them the VIP experience at our attractions, we could also be giving them more opportunities with whitelists for our other projects in other locations.</p>
                                   
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Roadmap;