import axios from "axios";

const http = axios.create({
  // baseURL: "https://thingproxy.freeboard.io/fetch/https://thepicab.com/landminting/API/v1/",
  baseURL: "https://thepicab.com/landminting/API/v1/",
  headers: {
    'Access-Control-Allow-Credentials': true,
    "Authorization":"Basic YWRtaW46MTIzNA==",
    "App-Secret-Key":"Mn2fKZG4M1170jDlVn6lOFTN6OE27f6UO99n9QDV",
    "Authorization-Token":"eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig",
    'Content-Type': 'application/json',
  },
});

const get = (param) => {
  return http.post(param);
};

const post = (param,data) => {
   return http.post(param,data);
}

const Services = {
  get,
  post,
};

export default Services;