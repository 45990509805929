import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Container , Row , Col } from "react-bootstrap";
import "./MintStyle.css";
import land from "../../assets/images/hero_img.svg";
import logo from "../../assets/images/Common/ecotech_logo.svg";
import minus from "../../assets/images/minus.svg";
import plus from "../../assets/images/plus.svg";
import Web3 from "web3";
import TestContract from '../../assets/abis/Marketing.json';
import { isMobile } from "react-device-detect";
import Services from '../../helpers/Service';
import keccak256 from "keccak256"
import { MerkleTree } from "merkletreejs"
import web3_utils from "web3-utils";
import { Link } from 'react-router-dom';
import back from "../../assets/images/back_arrow.svg";

const MintPage = () =>{

    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [loding, setLoding] = useState(true);
    const [currentWeb3, setWeb3] = useState(true);
    const [totals, setTotals] = useState({
        minted:0,
        supply:0
    });
    const [cost, setCost] = useState({
        public:0,
        whitelist:0,
        privateWhitelist:0
    });
    const [whitelistSupply, setWhitelistSupply] = useState(0);
    const [privateWhitelistSupply, setPrivateWhitelistSupply] = useState(0);
    
    const [number, setNumber] = useState(1);
    const increamentNum = () =>{
        setNumber(number + 1);
    }
    const decreamentNum = () =>{
        if(number > 1){
            setNumber(number - 1);
        }
    }
        
    useEffect(() => {
        initFunction();
    },[account]);

    const initFunction = async() => {
        if(window.ethereum){
            setLoding(true)
            let web3 = new Web3(window.ethereum);
            setWeb3(web3);
            const TestContractObj = new web3.eth.Contract(
                TestContract.abi,
                TestContract.networks[4].address
            );
            setContract(TestContractObj)

            const totalSuplay = await TestContractObj.methods.maxSupply().call();
            const totalMinted = await TestContractObj.methods.totalSupply().call();
            setTotals({minted:Number(totalMinted), supply:Number(totalSuplay)})

            const maxWhitelist = await TestContractObj.methods.maxWhitelist().call();
            setWhitelistSupply(Number(maxWhitelist))

            const maxPrivateWhitelist = await TestContractObj.methods.maxPrivateWhitelist().call();
            setPrivateWhitelistSupply(Number(maxPrivateWhitelist))

            const pcost = await TestContractObj.methods.cost().call();
            console.log(Number(pcost));
            const wCost = await TestContractObj.methods.whitelistCost().call();
            const pwCost = await TestContractObj.methods.privateWhitelistCost().call();
            setCost({public:web3.utils.fromWei(pcost.toString()),whitelist:web3.utils.fromWei(wCost.toString()),privateWhitelist:web3.utils.fromWei(pwCost.toString())})
            setLoding(false)
        }else{
            if (!isMobile) {
                  alert('Please install MetaMask!');
            }
        }
    };

    const updateDetails = async() =>{
        const totalMinted = await contract.methods.totalSupply().call();
        setTotals({minted:Number(totalMinted), supply: totals.supply})
    }

    const connectWallet = async() => {
        if (window.ethereum) {
            setLoding(true)
            let web3 = new Web3(window.ethereum);
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const networkId = await window.ethereum.request({
                    method: "net_version",
                });
                
                if (networkId != 4) {
                    alert("Please change network to Rinkeby Testnet");
                }

                setAccount(accounts[0])
                setLoding(false)
               
                window.ethereum.on("accountsChanged", (accounts) => {
                    setAccount(accounts[0]);
                });
                window.ethereum.on("chainChanged", () => {
                  window.location.reload();
                });
                
            } catch (err) {
              console.log("Something went wrong.");
            }
          } else {
            if (isMobile) {
              window.location.href = "https://metamask.app.link/dapp/thepicab.com/landminting/mint";
            }else{
              alert("Install Metamask.");
            } 
         }
    }

    const mint = async(e) => {
        e.preventDefault();
        // const web3 = window.web3
        const thisss = this;
        setLoding(true)

        const amount = number

        const totalWhitelistMinted = await contract.methods.totalWhitelistSupply().call();
        const totalPrivateWhitelistMinted = await contract.methods.totalPrivateWhitelistSupply().call();

        let isWhitelisted = false;
        if(Number(totalWhitelistMinted) < whitelistSupply){
            isWhitelisted = true;
        }
        let isPrivateWhitelisted = false;
        if(Number(totalPrivateWhitelistMinted) < privateWhitelistSupply){
            isPrivateWhitelisted = true;
        }
        
        const isWhitelist = await Services.post("isWhitelist", {address:account, isInDoglist:isWhitelisted, isPrivate:isPrivateWhitelisted});
        if(isWhitelist.data.status){

            const whitelistLeave = isWhitelist.data.data.map(data => keccak256(String(data.wallet)));
            const tree = new MerkleTree(whitelistLeave, keccak256, { sort: true })
            const merkleProof = tree.getHexProof(keccak256(String(account)))
       
            let price = web3_utils.toWei((Number(cost.public)*amount).toString());

            if(isWhitelist.data.type == 'dogs'){
                price = web3_utils.toWei((Number(cost.whitelist)*amount).toString());
            }else if(isWhitelist.data.type == 'alpha'){
                price = web3_utils.toWei((Number(cost.privateWhitelist)*amount).toString());
            }

            contract.methods.mint(amount, merkleProof).send({ from: account, value:Number(price) }).once('transactionHash', (transactionHash) => {
                waitForReceipt(transactionHash, async function(response) {
                    if(response.status){
                        updateDetails()
                        alert("NFT mint Successfully")
                        setNumber(1)
                        setLoding(false)
                    }else{
                        alert(response.msg);
                        setLoding(false)
                    }
                });
            });

        }else{
            alert(isWhitelist.data.msg);
            setLoding(false)
        }
    }

    const waitForReceipt = async(hash, cb) =>{
        const thiss = this;
        currentWeb3.eth.getTransactionReceipt(hash, function (err, receipt) {
            if (err) {
              console.log(err);
            }  
        
            if (receipt !== null) {
              if (cb) {
                  if(receipt.status == '0x0') {
                      cb({status:false, msg: "The contract execution was not successful, check your transaction !"});
                  } else {
                      cb({status:true, msg:"Execution worked fine!"});
                  }
              }
            } else {
              window.setTimeout(function () {
                waitForReceipt(hash, cb);
              }, 1000);
            }
        });
    }

    return(
        <>
           
            <div className="mint_main" >
                <Container fluid >
                    <Link to="/" className='back_arrow' >
                        <img src={back} alt="arrow" />
                        <span>BACK</span>
                    </Link>
                </Container>
                <Container>
                    <h1 className='coming-soon text-center' >Coming Soon</h1>
                    <Row className="align-items-center justify-content-between d-none" >
                        <Col lg={{ order: 'first' , span: 4 }} md={{ order: 'last' , span: 12 }} sm={{ order: 'last' , span: 12 }} xs={{ order: 'last' , span: 12 }} >
                            <div className="mint-image" >
                                <img src={land} alt="mintimage" />
                            </div>
                        </Col>
                        <Col lg={{ order: 'last' , span: 6 }} md={{ order: 'first' , span: 12 }} sm={{ order: 'first' , span: 12 }} xs={{ order: 'first' , span: 12 }} >
                            <div className="mint-box" >
                                <img src={logo} alt="logo" className="mint-logo" />
                                <h5>MINT YOUR LAND</h5>
                                <p>Start minting and claim unique NFT'S!</p>
                                {account && (
                                    <h6>Wallet ID : {account.substring(0, 5)+'...'+account.slice(-5)}</h6>
                                )}
                                <h3>{totals.minted}/{totals.supply}</h3>
                                <p className="land-cost" >1 Land Costs {cost.public} ETH</p>
                                <form method='POST'onSubmit={mint} style={{textAlign:'center'}}>
                                    <div className="claim-land" >
                                        <span onClick={decreamentNum} ><img src={minus} alt="minus" /></span>
                                        <h5>{number}</h5>
                                        <span onClick={increamentNum} ><img src={plus} alt="plus" /></span>
                                    </div>
                                    { (account) ? (
                                        <>  
                                            { loding ? (
                                                <button disabled>Loding...</button>
                                            ):(
                                                <button type="submit">CLAIM</button>
                                            )}
                                        </>
                                    ):(
                                        <button type='button' onClick={connectWallet}>Connect Wallet</button>
                                    )}
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default MintPage;