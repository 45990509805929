import React from 'react';
import { Container , Row , Col , Form } from "react-bootstrap";
import location from "../../assets/images/pin.svg";

const Contact = () =>{
    return(
        <>
            <div id="contact" className="common contact_main" >
                <h5 className="heading text-center" >Contact Us</h5>
                <Container>
                    <div className="contact_section" >
                        <Col lg={12} >
                            <Row>
                                <Col lg={8} >
                                    <div className="contact-form" >
                                        <h5>Send us a Message</h5>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} >
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="floatingInputCustom" type="text" placeholder="Full Name" />
                                                    <label htmlFor="floatingInputCustom">Full Name</label>
                                                </Form.Floating>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} >
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="floatingInputCustom" type="text" placeholder="Company Name" />
                                                    <label htmlFor="floatingInputCustom">Company Name</label>
                                                </Form.Floating>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} >
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="floatingInputCustom" type="email" placeholder="Enter Email" />
                                                    <label htmlFor="floatingInputCustom">Email</label>
                                                </Form.Floating>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} >
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="floatingInputCustom" type="text" placeholder="Contact Number" />
                                                    <label htmlFor="floatingInputCustom">Contact Number</label>
                                                </Form.Floating>
                                            </Col>
                                            <Col lg={12} >
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="floatingInputCustom" type="text" placeholder="Subject" />
                                                    <label htmlFor="floatingInputCustom">Subject</label>
                                                </Form.Floating>
                                            </Col>
                                            <Col lg={12} >
                                                <Form.Floating className="mb-3">
                                                    <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '100px' }} />
                                                    <label htmlFor="floatingInputCustom">Your message.....</label>
                                                </Form.Floating>
                                            </Col>
                                            <Col lg={12} >
                                                <button>SEND A MESSAGE</button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={4} >
                                    <div className="contact-information" >
                                        <h5>Contact Information</h5>
                                        <div className="adress" >
                                            <div className='group-horizontal'>
                                            <img src={location} alt="location" />
                                            <p>Royal Wings Technology Pte Ltd <br /> 28 Tai Seng Street #04-01 S(534106) </p>
                                            </div>
                                            <iframe 
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7353748454307!2d103.88725001409178!3d1.3349782619965378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da178d68eb6161%3A0x6d4159d68091dda4!2s28%20Tai%20Seng%20St%2C%20%2304%2001%20s%2C%20Singapore%20534106!5e0!3m2!1sen!2sin!4v1657178547703!5m2!1sen!2sin" 
                                                width="100%" 
                                                height="250" 
                                                allowFullScreen="" 
                                                loading="lazy" 
                                                referrerPolicy="no-referrer-when-downgrade">
                                            </iframe>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Contact;