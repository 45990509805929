import React from 'react';
import { Col, Row , Container } from "react-bootstrap";
import eco from "../../assets/images/Eco.svg";
import green from "../../assets/images/Green_Projects.svg";
import nft from "../../assets/images/Nft_Technology.svg";

const Experiance = () =>{
    const data = [
        {
            id: 1,
            experianceimage: eco,
            title: "Eco-Agricultural"
        },
        {
            id: 2,
            experianceimage: green,
            title: "Sustainability Green Projects"
        },
        {
            id: 3,
            experianceimage: nft,
            title: "NFT Technology "
        },
    ]
    return(
        <>
            <section id="experiance" className="experiance_main" >
                <div id="experiance-wrap"></div>
                <h5 className="heading text-center" >Our Experience</h5>
                <p className="subheading" >DigiGuru is a groundbreaking NFT project founded by two experienced entrepreneurs from the travel and recreation industry, Royal Wings Travel and Axe Factor. The first project will release a collection of 10,000 NFTs that serve as a medium of membership, allowing holders to redeem free or discounted stays on the island of Batam. Our goal is to provide a unique travel experience that is affordable without compromising the quality of the stay. In Phase one of the project we will be looking and curating rooms for stay on the island of Batam, Indonesia. On the stipulated area of land, we will build the first Lux Capsules over a period of 6 months and then start delivering luxurious stays for our holders.</p>
                <Container>
                <Row className="justify-content-center" >
                    <Col xl={10} >
                        <Row>
                            {data.map((d,i) => (
                                <Col lg={4} md={6} sm={6} key={"data_"+i}>
                                    <div className="experiance_list" >
                                        <img src={d.experianceimage} alt="eco" width="180" height="auto" />
                                        <h5>{d.title}</h5>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                </Container>
            </section>
        </>
    );
}

export default Experiance;