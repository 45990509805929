import React from "react";
import logo from "../../assets/images/Common/land.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import discord from "../../assets/images/discord.svg";
import twitter from "../../assets/images/twitter.svg";

const Navbar = () => {
  return (
    <footer className="footer_section ">
      <img src={logo} alt="land" />
      <ul>
        <li><a href="https://www.instagram.com/digiguru.land/" target="_blank" ><img src={instagram} alt="instagram" /></a></li>
        <li><a href="https://www.facebook.com/DigiGuru-103646479043530" target="_blank" ><img src={facebook} alt="facebook" /></a></li>
        <li><a href="https://discord.com/invite/2naU3DWQ" target="_blank" ><img src={discord} alt="discord" /></a></li>
        <li><a href="https://twitter.com/DigiguruL" target="_blank" ><img src={twitter} alt="twitter" /></a></li>
      </ul>
      {/* <p className="footer_version_text">Presented by Royal wings technology</p> */}
    </footer>
  );
};

export default Navbar;
