import React from 'react';
import { routes } from "../src/constants";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import MintPage from "./pages/Mint/MintPage";

const getRoutes = (user) => [
  {
    path: routes.dashboard,
    children: [{ path: routes.dashboard, element: <DashboardPage /> }],
  },
  {
    path: routes.mint,
    children: [{ path: routes.mint, element: <MintPage /> }],
  },
];

export default getRoutes;
