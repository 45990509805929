import React from 'react';
import Header from "../../component/Layout/Header";
import "./Dashboard.css";
import HeroSection from "./HeroSection";
import Experiance from "./Experiance";
import Team from "./Team";
import VisionMission from "./VisionMission";
import About from "./About";
import Opportunities from "./Opportunities";
import Roadmap from "./Roadmap";
import Footer from "../../component/Layout/Footer";
import Contact from "./Contact";
import Milestone from './Milestone';

const DashboardPage = () => {
  return (
    <>
      <div className="dashboard-main" >
        <Header />
        <HeroSection />
        <Experiance />
        <VisionMission />
        <About />
        <Opportunities />
        <Roadmap />
        <Team />
        {/* <Milestone /> */}
        <div id="contact-wrap" ></div>
        <div style={{background:"rgba(14, 44, 38, 0.95)", display: "flow-root", marginTop: "70px"}}>
        <Contact />
        <Footer />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
