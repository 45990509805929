import { Col, Container, Row , Modal } from "react-bootstrap";
import React, { Component } from "react";
import Slider from "react-slick";
import { useState } from "react";

import team1 from "../../assets/images/user1.png";
import team2 from "../../assets/images/user2.png";
import team5 from "../../assets/images/user5.png";
import team6 from "../../assets/images/user6.png";
import team8 from "../../assets/images/user8.png";

const Team = () =>{
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <>
            <section id="management" className="team_main common" >
                <Container>
                    <Row className="align-items-center" >
                        <Col lg={12} >
                            <div className="team_content" >
                                <h5 className="heading" >Our Management Team</h5>  
                                <p>Travel & Tourism sectors. A strong passion for the tourism and hospitality industry.Proven track record of developing and implementing business strategies. Sharing knowledge and expertise in a highly professional manner.Decision making in a pressured, commercial driven environment. Dynamic sales & marketing driver.</p>
                            </div>
                        </Col>
                        <Col lg={12} >
                            <div className="team_image" >
                                <Slider {...settings}>
                                    <div className="team_detail" >
                                        <img src={team6} alt="" />
                                        <h4>Diana</h4>
                                        <h6>Chief Planner</h6>
                                        <p>A strong passion for the tourism and hospitality industry. Proven track record of developing and implementing business strategies.</p>
                                    </div>
                                    <div className="team_detail" >
                                        <img src={team8} alt="" />
                                        <h4>Clarence</h4>
                                        <h6>Chief Architect</h6>
                                        <p>With more than 15 years of experience from design, concept to building architectural works. His fine and innovative works allow him & his team continually...<a onClick={handleShow} href="#" >Read More</a></p>
                                    </div>
                                    <div className="team_detail" >
                                        <img src={team5} alt="" />
                                        <h4>Derrick</h4>
                                        <h6>Chief of Operations</h6>
                                        <p>Experienced management & a certified Digital Business Professional.</p>
                                    </div>
                                    <div className="team_detail" >
                                        <img src={team2} alt="" />
                                        <h4>Kapil</h4>
                                        <h6>Chief of Technology</h6>
                                        <p>Blockchain Start-up Specialist , work on various decentralised and block chain based ideas and innovation to make this world Equal for all.</p>
                                    </div>
                                    <div className="team_detail" >
                                        <img src={team1} alt="" />
                                        <h4>Stephan</h4>
                                        <h6>Chief of Marketing</h6>
                                        <p>Stephan has been in the marketing and events industry since 2013 and now serves in the marketing and community department of DigiGuru.land</p>
                                    </div>
                                </Slider>
                                {/* <Row>
                                    {data.map((d,i) => (
                                        <Col lg={3} md={6} sm={6} key={"team_"+i}>
                                            <div className="team_detail" >
                                                <img src={d.teamimg} alt="" />
                                                <h4>{d.membername}</h4>
                                                <h6>{d.memberrole}</h6>
                                                <p>{d.memberabout}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
            >   
                <Modal.Body>
                    <div className="phase" >
                        <h5>Clarence</h5>
                        <p>With more than 15 years of experience from design, concept to building architectural works. His fine and innovative works allow him & his team continually proven to be an industry leader for its innovative spatial typology design and excellent built quality control. Its works often featured in international media. Clarence founded AND Lab architect firm.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Team;